<template>
  <div class="edit">
    <h1>
      Configura los premios
    </h1>
    <div v-if="prizes.length > 0" class="prizeContainer" ref="prizeContainer">
      <div v-for="(prize, index) in prizes" :key="prize.id" class="prize">
        <div class="indexNumber">{{ index + 1 }}</div>
        <div class="deletePrize" @click="del(index)"><i class="fas fa-trash"></i></div>
        <div class="inputRow list">
          <div class="inputGroup">
            <label :for="`prizes[${index}][name]`">Nombre:</label>
            <input
              type="text"
              v-model="prize.name"
              :name="`prizes[${index}][name]`"
            />
          </div>
          <div class="inputGroup">
            <label :for="`prizes[${index}][description]`">Descripción:</label>
            <input
              type="text"
              v-model="prize.description"
              :name="`prizes[${index}][description]`"
            />
          </div>
          <div class="inputGroup">
            <label :for="`prizes[${index}][title]`">Titulo:</label>
            <input
              type="text"
              v-model="prize.title"
              :name="`prizes[${index}][title]`"
            />
          </div>
          <div class="inputGroup">
            <label :for="`prizes[${index}][subTitle]`">Sub Titulo:</label>
            <input
              type="text"
              v-model="prize.subTitle"
              :name="`prizes[${index}][subTitle]`"
            />
          </div>
        </div>
        <div class="divider"></div>
        <div class="inputRow">
          <div class="inputGroup">
            <label for="">Color</label>
            <v-swatches
              v-model="prize.backgroundColor"
              :swatches="swatches"
              shapes="circles"
            ></v-swatches>
          </div>
          <div class="inputGroup icon">
            <label for="">Icono</label>
            <vfa-picker v-model="prize.icon" :only="['solid']">
              <template v-slot:activator="{ on }">
                <!-- <input v-model="prize.icon" @click="on" placeholder="Icon Class" type="text" /> -->
                <i :class="`fas fa-${prize.icon}`" @click="on"></i>
              </template>
            </vfa-picker>
          </div>
          <div class="inputGroup image">
            <label for="">Imagen</label>
            <label class="imageLabel" :for="`prizes[${index}][image]`"><img :src="prize.image" alt="">
            <input type="file" :id="`prizes[${index}][image]`" :name="`prizes[${index}][image]`" accept="image/*" @change="handleImage($event, index)" class="imageLoader"></label>
          </div>

          <div class="inputGroup" style="min-width: 14vh">
            <label for="">{{ prize.winner ? "Cantidad" : "Activo" }}</label>
            <button
              
              v-on="prize.stock >0 ? { click: () => prize.stock-- } : {click: ($event) => $event.preventDefault() }"
              v-if="prize.winner"
              class="spinnerBtn"
            >
              <i class="fas fa-minus"></i>
            </button>
            <input
              :type="prize.winner ? 'number' : 'checkbox'"
              class="plus-minus"
              true-value="1"
              false-value="0"
              v-model="prize.stock"
              :name="`prizes[${index}][stock]`"
            />
            <button
              @click="prize.stock++"
              v-if="prize.winner"
              class="spinnerBtn"
            >
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <div class="inputGroup" style="min-width: 6vh">
            <label for="">¿Es premio?</label>
            <input
              type="checkbox"
              class="plus-minus"
              v-model="prize.winner"
              :name="`prizes[${index}][winner]`"
            />
          </div>
        </div>

        <!-- {{index +1 }} - {{prize.name}} -->
        <!-- <label for="" v-else>a</label> -->
      </div>
      <button class="reset" @click="reset"><i class="fas fa-undo-alt"></i></button>
      <button class="add" @click="add"><i class="fas fa-plus"></i></button>
      <button class="save" @click="submit">Guardar</button>
      <button class="cancel" @click="cancel">Cancelar</button>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import VSwatches from "vue-swatches";

export default {
  name: "Edit",
  components: { VSwatches },
  directives: {},
  data: () => ({
    prizes: [],
    _prizesState: [],
    swatches: ["#f8b218", "#8fc640", "#222222", "#ff3c00"]
  }),
  computed: {
    // sortedPrizes() {
    //   return this.activePrizes.slice().reverse();
    // },
    // activePrizesQty() {
    //   return this.prizes.reduce(function (n, prize) {
    //     return n + (prize.stock != 0);
    //   }, 0);
    // },
    // activePrizes() {
    //   // let activePrizes =
    //   return this.prizes.filter((prize) => {
    //     return prize.stock != 0;
    //   });
    // },
    // currentItem() {
    //   return Math.floor(
    //     Math.abs(this.roulette.angle) / (360 / this.activePrizesQty)
    //   );
    // },
  },
  created: function () {},
  mounted: function () {
    this.prizes = this.$store.state.prizes;
    this._prizesState = JSON.parse(JSON.stringify(this.prizes));
    for (let i = 0; i < this.prizes.length; i++) {
        this.prizes[i].stock = String(this.prizes[i].stock);
      }
    console.log(this.prizes);
  },
  methods: {
    onNext() {
      this.$router.push({ path: "/" });
    },
    handleImage(e, index){
      console.log("e", e);
      console.log("index", index);
      const selectedImage = e.target.files[0];
      this.createBase64Image(selectedImage, index)
    },
    createBase64Image(file, index){
      const reader = new FileReader();
      // reader.onload = (e) => {
      //   this.prizes[index].image = e.target.result;
      // };
      
      // reader.readAsBinaryString(file)

      const readerBase64 = new FileReader();

    reader.onloadend = () => {
        let realMimeType = this.getRealMimeType(reader);
        if (realMimeType !== 'unknown') {
            readerBase64.readAsDataURL(file);
        } else {
            alert("Please upload a valid image file");
        }
    };

    reader.readAsArrayBuffer(file);

    readerBase64.onloadend = (e) =>{
        let base64 = e.target.result;
        this.prizes[index].image = base64;
    };
      console.log( this.prizes[index].image);
    },
    getRealMimeType(reader){
    var arr = (new Uint8Array(reader.result)).subarray(0, 4);
    var header = '';
    var realMimeType;

    for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
    }

    // magic numbers: http://www.garykessler.net/library/file_sigs.html
    switch (header) {
        case "89504e47":
            realMimeType = "image/png";
            break;
        case "47494638":
            realMimeType = "image/gif";
            break;
        case "ffd8ffDB":
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            realMimeType = "image/jpeg";
            break;
        default:
            realMimeType = "unknown"; // Or you can use the blob.type as fallback
            break;
    }

    return realMimeType;
    },
    submit() {
      console.log("click: ", this.prizes);
      for (let i = 0; i < this.prizes.length; i++) {
        this.prizes[i].stock = parseInt(this.prizes[i].stock);
      }
      this.$store.commit("setAllPrizes", this.prizes);
      this.$router.push({ path: "/" });
    },
    add(){
      let prizeTemplate = {
        id: `premio ${this.prizes.length+1}`,
        name: "",
		    title: "",
		    subTitle: "",
        description: "",
        image: "img/fury.png",
        icon: "fal fa-circle",
        color: "#ffffff",
        backgroundColor: "#f8b218",
        winner: true,
        stock: 0,
      };

      this.prizes.push(prizeTemplate);
      setTimeout(() => {
        var container = this.$refs.prizeContainer;
        container.scrollTop = container.scrollHeight;
      }, 100);
    
    },
    del(index){
      if(this.prizes.length > 2){
        this.prizes.splice(index, 1)
      } else {
        alert("¡Deben existir al menos dos premios!")
      }
    },
    cancel(){
      this.$store.commit("setAllPrizes", this._prizesState);
      this.$router.push({ path: "/" });
    },
    reset(){
      let r = confirm("¿Seguro que desea reestablecer los premios?");
      if (r == true) {
        console.log('resetting prizes');
        this.prizes = JSON.parse(JSON.stringify(this.$store.state.defaultPrizes));
        this.$store.commit("setAllPrizes", JSON.parse(JSON.stringify(this.$store.state.defaultPrizes)));
        
      }
    }
  },
};
</script>


<style lang="scss" >
.edit {
  color: white;
  // display: flex;
  // position: relative;
  // z-index: 99999;

  .prizeContainer {
    padding: 2vh;
    height: 90vh;
    overflow-y: scroll;
    // display: flex;
    // flex-direction: column;

    .prize {
      background-color: #eeeeee;
      border-radius: 1vh;
      margin: 2vh;
      color: black;
      padding: 2vh;
      position: relative;
      display: inline-flex;
      flex-direction: column;
      // width: 60vw;

      .indexNumber {
        width: 3vh;
        height: 3vh;
        line-height: 3.3vh;
        text-align: center;
        border-radius: 2vh;
        background-color: #111111;
        font-weight: 900;
        font-size: 1.75vh;
        color: #ffffff;
        position: absolute;
        top: -1.5vh;
        left: -1.5vh;
        // box-sizing: border-box;
        border: 0.5vh solid #ffffff;
      }

      .deletePrize{
        width: 3vh;
        height: 3vh;
        line-height: 3.3vh;
        text-align: center;
        border-radius: 2vh;
        background-color: #ff3c00;
        font-weight: 900;
        font-size: 1.75vh;
        color: #ffffff;
        position: absolute;
        top: -1.5vh;
        right: -1.5vh;
        // box-sizing: border-box;
        border: 0.5vh solid #ff3c00;
      }

      .divider {
        width: 100%;
        margin: 1vh 0;
        border: 1px solid #111;
      }
      .inputRow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
          justify-content: space-between;

        .inputGroup {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          justify-items: center;
          flex-direction: row;
          flex-wrap: wrap;
          // min-width: 14vh;
          // padding: 0 1vh;
          height: 4vh;
          box-sizing: border-box;
          position: relative;
          margin: 0 1vh;
          margin-top: 2.5vh;
          // padding-top: 4vh;

          .vue-swatches{

            .vue-swatches__trigger__wrapper{
              .vue-swatches__trigger{
                height: 4vh!important;
                width: 4vh!important;
                overflow:hidden;
                
              }
            }

          }

          label:not(.imageLabel) {
            font-size: 1.5vh;
            position: absolute;
            top: -2.5vh;
            white-space: nowrap;
          }
          &.icon {
            min-width: 5vh;
            font-size: 4vh;
            text-align: center;
          }
          &.image {
            img{
              height: 4vh;
            }
            // min-width: 5vh;
            // font-size: 4vh;
            // text-align: center;
          }
        }

        &.list {
          // align-content: space-between;
          // align-items: center;
          text-align: left;
          flex-direction: column;
          .inputGroup {
            display: inline-flex;
          justify-content: center;
          align-items: center;
          justify-items: center;
          flex-wrap: nowrap;
          padding-top: 0;
            label {
              position: relative;
              font-size: 2vh;
              width: 20vh;
              top: 0;
            }
            justify-content: space-between;
            margin: 0.5vh 0;
          }
        }
      }
      .spinnerBtn {
        display: inline-block;
        background-color: #f8b218;
        width: 4vh;
        height: 4vh;
        line-height: 4.5vh;
        font-size: 2vh;
        text-align: center;
        border-radius: 2vh;
        outline: none;
      }
    }
  }

  input {
    font-size: 2vh;

    &[type="number"] {
      width: 4vh;
      height: 4vh;
      line-height: 4.5vh;
      font-size: 2vh;
      text-align: center;
      // pointer-events: none;
    }

    &[type="file"] {
      display: none;
    }

    &[type="text"] {
      height: 4vh;
      line-height: 4.5vh;
      width: 100%;
      font-size: 2vh;
      text-align: left;
      padding: 0 1vh;
      border: 0.2vh solid #f8b218;
      border-radius: 0.5vh;
      outline: none;
    }
  }

  button.reset {
    position: absolute;
    background-color: #ffffff;
    // border-radius: 2vh;
    border-top-left-radius: 10vh;
    border-bottom-left-radius: 10vh;
    border: 1.5vh solid black;
    border: none;
    border-right: none;
    padding: 2vh;
    text-align: left;
    width: 8vh;
    height: 4vh;
    line-height: 4.5vh;
    outline: none;
    color: #111;
    font-size: 5vh;
    font-weight: 900;
    z-index: 999999;
    transform: rotate(-20deg) translateX(4vh);
    top:8vh;
    right: 0vh;
    transition: 0.3s transform;
  }

  button.add {
    position: absolute;
    background-color: #f8b218;
    // border-radius: 2vh;
    border-top-left-radius: 10vh;
    border-bottom-left-radius: 10vh;
    border: 1.5vh solid black;
    border: none;
    border-right: none;
    padding: 4vh;
    text-align: left;
    width: 8vh;
    height: 6vh;
    line-height: 6.5vh;
    outline: none;
    color: white;
    font-size: 6vh;
    font-weight: 900;
    z-index: 999999;
    transform: rotate(-20deg) translateX(4vh);
    top: 20vh;
    right: 0vh;
    transition: 0.3s transform;
  }

  button.save {
    position: absolute;
    background-color: #8fc640;
    // border-radius: 2vh;
    border-top-left-radius: 10vh;
    border-bottom-left-radius: 10vh;
    border: 1.5vh solid black;
    border: none;
    border-right: none;
    padding: 4vh 6vh;
    text-align: left;
    width: 20vh;
    height: 6vh;
    line-height: 6.5vh;
    outline: none;
    color: white;
    font-size: 5vh;
    font-weight: 900;
    z-index: 999999;
    transform: rotate(-20deg) translateX(4vh);
    bottom: 20vh;
    right: 0vh;
    transition: 0.3s transform;
  }

  button.cancel {
    position: absolute;
    background-color: #ff3c00;
    // border-radius: 2vh;
    border-top-left-radius: 10vh;
    border-bottom-left-radius: 10vh;
    border: 1.5vh solid black;
    border: none;
    border-right: none;
    padding: 4vh 4vh;
    text-align: left;
    width: 20vh;
    height: 2vh;
    line-height: 2.2vh;
    outline: none;
    color: white;
    font-size: 4vh;
    font-weight: 900;
    z-index: 999999;
    transform: rotate(-20deg) translateX(4vh);
    bottom: 8vh;
    right: 0vh;
    transition: 0.3s transform;
  }
}

.back {
  display: inline;
  // position: absolute;
  // background: none;
  // outline: none;
  // border: none;
  color: white;
  font-size: 3vh;
  // bottom: 2vh;
  // left: 2vh;
}

.plus-minus[type="checkbox"] {
  --primary: #f8b218;
  --secondary: #fafbff;
  --duration: 0s;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-tap-highlight-color: transparent;
  // -webkit-mask-image: -webkit-radial-gradient(white, black);
  outline: none;
  cursor: pointer;
  text-align: center;
  // position: relative;
  // display: inline;
  position: absolute;
  // top: 1vh;
  overflow: hidden;
  // transform-style: preserve-3d;
  // perspective: 240px;
  border-radius: 50%;
  width: 4vh;
  height: 4vh;
  border: 0.5vh solid #111111;
  background-size: 300% 300%;
  // transition: transform .3s;
  // transform: scale(var(--scale, 1)) translateZ(0);
  animation: var(--name, unchecked) var(--duration) ease forwards;
  // &:before,
  // &:after {
  //     content: '';
  //     position: absolute;
  //     width: 16px;
  //     height: var(--height, 16px);
  //     left: 6px;
  //     top: var(--top, 6px);
  //     background: var(--background, var(--primary));
  //     animation: var(--name-icon-b, var(--name-icon, unchecked-icon)) var(--duration) ease forwards;
  // }
  // &:before {
  //     clip-path: polygon(0 6px, 6px 6px, 6px 0, 10px 0, 10px 6px, 16px 6px, 16px 10px, 10px 10px, 10px 16px, 6px 16px, 6px 10px, 0 10px);
  // }
  // &:after {
  //     --height: 4px;
  //     --top: 12px;
  //     --background: var(--secondary);
  //     --name-icon-b: var(--name-icon-a, checked-icon);
  // }
  &:active {
    --scale: 0.95;
  }
  &:checked {
    --name: checked;
    // --name-icon-b: checked-icon;
    // --name-icon-a: unchecked-icon;
  }
}

// @keyframes checked-icon {
//     from {
//         transform: translateZ(12px);
//     }
//     to {
//         transform: translateX(16px) rotateY(90deg) translateZ(12px);
//     }
// }

// @keyframes unchecked-icon {
//     from {
//         transform: translateX(-16px) rotateY(-90deg) translateZ(12px);
//     }
//     to {
//         transform: translateZ(12px);
//     }
// }

@keyframes checked {
  from {
    background-image: radial-gradient(
      ellipse at center,
      var(--primary) 0%,
      var(--primary) 20%,
      var(--secondary) 20.1%,
      var(--secondary) 100%
    );
    background-position: 100% 50%;
  }
  to {
    background-image: radial-gradient(
      ellipse at center,
      var(--primary) 0%,
      var(--primary) 20%,
      var(--secondary) 20.1%,
      var(--secondary) 100%
    );
    background-position: 50% 50%;
  }
}

@keyframes unchecked {
  from {
    background-image: radial-gradient(
      ellipse at center,
      var(--secondary) 0%,
      var(--secondary) 25%,
      var(--primary) 25.1%,
      var(--primary) 100%
    );
    background-position: 100% 50%;
  }
  to {
    background-image: radial-gradient(
      ellipse at center,
      var(--secondary) 0%,
      var(--secondary) 25%,
      var(--primary) 25.1%,
      var(--primary) 100%
    );
    background-position: 50% 50%;
  }
}
</style>